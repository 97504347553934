<template>
    <div class="radio_sub_box">
        <span
            class="label"
            v-if="label"
        >
            {{ label }}
        </span>
        <div class="flexbox">
            <slot></slot>
        </div>
    </div>
</template>

<script>
/**
 * ラジオボタンの選択結果に付随して表示されるボックス
 * 
 * [プロパティ]
 * label ... 見出しの文面（AppTextが入る場合は入力されない）
 * name ... ヘルプボタンが必要な場合、ヘルプのキーを入れる
 * url ... リンク先url
 * link-to ... リンクボタンの表示名を指定する
 * 
 * [イベント処理]
 * クリック時 ... this.$emit('click', this.name)
 * 
 * [利用例]
 * <app-radio-sub-box
 *  label="照明器具の種類"
 *  name="ls_main_design"
 *  @click="helpView"
 *  v-if="ls_main_setti == 2"
 *   url="https://~"
 *   link-to="tool"
 * >
 * (中に入れるコンポーネント等をここに入力)
 * </app-radio-sub-box>
 *
 */

export default {
    name:"AppRadioSubBox",
    props: {
        /**
         * 見出しの文面（AppTextが入る場合は入力されない）
         */
        label: {
            type: String,
        },
        /**
         * ヘルプボタンが必要な場合、ヘルプのキーを入れる
         */
        name:{
            type: String,
        },
        /**
         * リンクボタン表示用のキー
         */
        url: {
            type:String
        },
        /**
         * リンク先のタイプ（現状は[tool]1種類です。）
         */
        linkTo: {
            type: String,
        },
    },
    methods: {
        /**
         * クリックされたら親コンポーネントにイベント発生を通知（ヘルプ表示）
         */
        clicked(){
            this.$emit('click', this.name)
        }
    }
}
</script>

<style scoped>
.radio_sub_box{
    display: -ms-grid;
    display: grid;
    grid-template-columns: auto 1fr;
    -ms-grid-columns: auto 1fr;/* IE11 */
    flex-direction: column;
    margin-left: 20px;
    padding: 8px 4px;
    background-color: rgba(96, 194, 218, 0.1);
    z-index: 2;
    position: relative;
}
.radio_sub_box:before{
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    border-left: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    left: -12px;
    top: -6px;
    position: absolute;
    z-index: 0;
}
span{
    display: inline-block;
    width: auto;
    margin-left: 25px;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    font-weight: bolder;
    color: #1C4373;
}
.label{
    grid-column: 1;
    -ms-grid-column: 1;
    margin:0;
    padding: 3px 8px 0 25px;
}
.flexbox{
    grid-column: 2;
    -ms-grid-column: 2;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
}

.label+.flexbox{
    padding-left: 4px;
}

/* 質問項目が2項目以上がある場合 */
.radio_sub_box+.radio_sub_box{
    border-top: 2px solid white;
}

.radio_sub_box+.radio_sub_box:before{
    content: "";
    display: inline-block;
    border: none;
}

/* ラジオボタンの場合 */
.radio_sub_box >>> .radio_label{
    margin-left: 38px;
}
.radio_sub_box >>> .radio_label:checked:after{
    left: 0;
}

/* ラジオボタンでかつラベルがある場合（.gridinbox使用） */
.radio_sub_box >>> .gridinbox span{
    margin-left: 0;
}
.radio_sub_box >>> .gridinbox .radio_label{
    margin-left: 16px;
}


/* リンクボタン */
.radio_sub_box >>> a.link_button{
    margin-left: 0;
    background-color: white;
}

.radio_sub_box >>> a.link_button:hover{
    background-color:#1C4373;
}

</style>