<template>
    <button
        @click="onclick"
        :class="{updated: is_update}"
        :disabled="disabled"
    >
        {{name}}
    </button>    
</template>
<script>
export default {
    name: 'AppButton',
    props:{
        /**
         * ボタン名
         * @type {String}
         */
        name: {
            type: String,
            required: true
        },
        is_update: {
            type: Boolean,
            default: false
        },
        /**
         * ボタンを無効化する場合はtrue
         */
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        onclick(event){
            this.$emit('click', event)
        }
    }
}
</script>
<style scoped>
    button{
        display: inline-block;
        box-sizing: border-box;
        background-color:#1C4373;
        color:#FFF;
        height:auto;
        line-height:1rem;
        padding: 0.5rem 2rem;
        margin:0.5rem;
        border: none;
        border-radius: 2px;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
    }
    button:hover{
        transform: scale(1.1);
        font-weight: bolder;
    }

    _:-ms-lang(x)::-ms-backdrop, button{
        padding: 0.6em 2rem 0.4rem
    }
    /* 色の変更 */
    button.cancel{
        color: white;
        background-color: #707070;
    }
    button.aquablue,
    button.simple{
        background-color: #53BFD8;
    }
    button.delete{
        background-color: #8a4646;
    }
    /* Disabled時 */
    button:disabled {
        opacity: 0.5;
        color: #999999;
        cursor: auto;
    }
    /* サイズの変更 */
    button.big{
        padding: 12px 50px;
        font-size: 22px;
        font-weight: bolder;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        background:linear-gradient( #6DC7DD,#41B8D4);
        border-radius: 4px;
    }
    button.big:hover{
        transform: scale(1);
        background:linear-gradient( #41B8D4,#6DC7DD);
    }
    button.wd_60px{
        width: 60px;
    }
    button.wd_80px{
        width: 80px;
        white-space: nowrap;
    }
    button.wd_200px{
        width: 200px;
        white-space: nowrap;
    }
    button.inline{
        padding: 0 8px 2px;
        background-color: transparent;
        color: #1C4373;
        border-bottom: 1px solid currentColor;
    }
    /* レイアウトの変更 */
    button.btn_center{
        display: block;
        margin: 0.5rem auto;
    }
</style>