import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//atoms
import AppButton from '@/components/atoms/AppButton.vue'
import AppErrorList from '@/components/atoms/AppErrorList.vue'
import AppRadio from '@/components/atoms/AppRadio.vue'
import AppRadioSubBox from '@/components/atoms/AppRadioSubBox.vue'
import AppText from '@/components/atoms/AppText.vue'
import InformationBox from '@/components/atoms/InformationBox.vue'

//molecules

//organisms
import AppBox from '@/components/organisms/AppBox.vue'
import AppSection from '@/components/organisms/AppSection.vue'
import InfoSection from '@/components/organisms/InfoSection.vue'

//templates
import Confirm from '@/components/templates/Confirm.vue'
import LayoutHeader from '@/components/templates/LayoutHeader.vue'
import TempDefault from '@/components/templates/TempDefault.vue'

Vue.component('confirm', Confirm)
Vue.component('layout-header', LayoutHeader)
Vue.component('app-section', AppSection)
Vue.component('info-section', InfoSection)
Vue.component('app-box', AppBox)
Vue.component('app-radio', AppRadio)
Vue.component('app-text', AppText)
Vue.component('app-button', AppButton)
Vue.component('app-error-list', AppErrorList)
Vue.component('app-radio-sub-box', AppRadioSubBox)
Vue.component('temp-default', TempDefault)
Vue.component('information-box', InformationBox)


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
