<template>
<div class="section_wrap">
    <slot name="h3" />
    <section class="app_section">
        <slot></slot>
    </section>
</div>
</template>

<script>
/**
 * 見出し付きセクション
 * 
 * [利用例]
 * <app-section id="envelope" label="外皮">
 *     <h3 slot="h3">
 *         <parts-arrow category="外皮">2</parts-arrow>
 *         <span>外皮の情報を入力して下さい。</span>
 *     </h3>
 *     (ここに中身が入ります)
 * </app-section>
 */
export default {
    name:"AppSection",
    props: {
        label: {
            type: String
        }
    }
}
</script>

<style scoped>
.section_wrap{
    display: block;
}
.section_wrap >>> h3{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto 2px;
    font-size: 18px;
    line-height: 22px;
    font-weight: bolder;
    color: #41B8D4;
}
.section_wrap >>> h3>span{
    display: block;
    padding-top: 17px;
}
section.app_section {
    width: 90%;
    min-width: 900px;
    margin: 0 auto 30px;
    border: 1px solid #41B8D4;
    border-radius: 5px;
    z-index: 5;
}
section.app_section >>> p{
    margin: 16px;
}

section.app_section.flex_center>div{
    margin-bottom: 0;
}
section.app_section.flex_center input[type="button"]{
    margin: 3px;
} 

/* インフォメーションボックスのレイアウト */
.app_section >>> .information{
    margin-right:0.5rem;
    margin-left:0.5rem;
}
/* レスポンシブ（タブレット縦よりウインドウ幅が狭いもの） */
@media screen and (max-width: 1000px) {
    .section_wrap >>> h3{
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: calc(100% - 16px);
        min-width: 150px;
        margin: 2px 8px;
    }
    .section_wrap >>> h3>span{
        box-sizing: border-box;
        width: calc(100% - 60px);
        min-width: 150px;
        margin: 2px 0;
    }
    section.app_section {
        width: calc(100% - 16px);
        min-width: 318px;
        margin: 2px 8px;
    }
}
</style>
<style>
.confirm section.app_section {
    border: 1px solid #00A0DE;
}
</style>
