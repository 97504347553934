<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
*{
  margin: 0;
  box-sizing: border-box;
}
#app{
  min-width: 300px;
  min-height: 100vh;
  margin: 0;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(83, 191, 216, 0.1);
  font-weight: 500;
  text-align: left;
  line-height: 1.5;
}
/* 文字間調整 */
p,span{
  font-feature-settings: "palt";
}
/* ボタンなどの青枠を消す */
input,
textarea,
select,
button,
summary {
  outline: none;
}
/* 書体が反映されていないものをフォロー */
button, input, select, textarea {
font-family : inherit;
font-size : 100%;
}
button,
input[type="button"]{
  cursor: pointer;
  outline:0;
}
label{
  color: #1C4373;
}
a{
  text-decoration: none;
}
.ly_buttonBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* transitionのアニメーション */
.toast-enter-active, .toast-leave-active {
  transform: scale(1, 0); 
  transform-origin: bottom;
  transition: all .3s ease;
}
.toast-enter-to {
  transform:  scale(1, 1);
}


/* 印刷用cssのスタイル */
@media print{
  body {
    size: A4;
    width:100%;
    max-width: 172mm !important;
    height: 251mm;
    margin: 0 auto;
    background:none;	/*ページ全体の背景色*/
    background-color: white;
    color: black;
    font-size: 12px;
    overflow-x: hidden;
  }
  div#app {
    width:100%;
    max-width: 172mm !important;
    margin: 0 auto;
    padding-top: 0;
    background:none;	/*ページ全体の背景色*/
    background-color: white;
    color: black !important;
  }
  main.bl_main.ly_main{
    margin: 0 auto;
    padding: 45px 0 0;
  }
  header#header.ly_header{
    height: 35px !important;
    background-color: white;
    color: black !important;
  }
  header#header.ly_header *{
    background-color: white;
    color: black !important;
  }
  header#header.ly_header .ly_header_inner{
    padding-left: 0;
  }
  header#header.ly_header .version{
    border: 1px solid black;
  }
  header#header.ly_header .bl_header_inner_icon{
    display: none;
  } 
  .bl_wrapper__2column{
    flex-wrap: nowrap !important;
  }
  .bl_wrapper__2column>div{
    min-width: 300px !important;
  }
  .bl_wrapper__2column>div:first-child{
    width: 200px !important;
    min-width: 200px !important;
    margin-right: 16px !important;
  }
  .bl_wrapper__2column>div:last-child{
    width: 650px !important;
  }
  .bl_wrapper__2column .section_wrap .app_section{
    border-color: black;
    color: black;
  }
  .bl_main .bl_wrapper .section_wrap h3{
    color: black;
  }
  .bl_main .bl_wrapper .section_wrap.uq_ondokoukankouritsu .app_section{
    width: 200px !important;
    min-width: 200px !important;
  }
  .bl_main .bl_wrapper .section_wrap.uq_ondokoukankouritsu h3{
    font-size: 12px;
  }
  .bl_main .bl_wrapper .section_wrap.uq_ondokoukankouritsu h3 span{
    padding-right: 16px;
  }
  .uq_ondokoukankouritsu .app_box{
    padding: 2px 16px !important;
  }
  .bl_main .bl_wrapper .app_box .grid_1{
    color: black;
  }
  .bl_main .bl_wrapper .app_box span{
    font-size: 10px;
    line-height: 12px;
    color: black;
  }
  .app_box.app_box__row{
    flex-direction: column !important;
  }
  .radio_wrapper label{
    color: black;
    font-size: 8px !important;
    flex-wrap: wrap;
  }
  .radio_wrapper label.radio_label.checked:before{
    color: black;
  }
  .app_section .information,
  .app_section .information p{
    margin: 0 !important;
  }
  .app_section .information,
  .app_section .information p{
    font-size: 10px !important;
  }
  .ly_resultMenu_inner_result{
    padding: 2px !important;
  }
  .bl_result .section_wrap section.app_section{
    background-color: white;
    padding: 10px 10px 0;
  }
  .bl_result .bl_resultMenu_inner__summary .bl_resultMenu_inner_result {
    border-color: black;
    margin-bottom: 10px;
  }
  .bl_calcProcess_list{
    flex-wrap: nowrap !important;
    color: black !important;
    border-color: #ccc !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .bl_result .bl_calcProcess .bl_calcProcess_list:not(:last-child){
    border-color: black;
  }
  .result_title>small{
    display: none;
  }
  .bl_calcProcess_list_data{
    width: fit-content !important;
    flex-wrap: nowrap !important;
    margin-right: 16px;
  }
  .bl_calcProcess_list_data_header{
    min-width: 13em;
    max-width: 13em;
    font-size: 10px !important;
    white-space: wrap;
  }
  .bl_calcProcess_list_data_value{
    font-size: 12px !important;
    min-width: 5em !important;
    }
  /* 矢印を消す。Chrome、Safari */
  label.bl_calcProcess_list .bl_calcProcess_list_data:before{
    display: none !important;
  }
  .bl_calcProcess_list_formula a img{
    max-width: 150px !important;
  }
  .bl_wrapper div.information{
    background-color: #fefefe;
  }
  
  .bl_wrapper .information>p.title,
  .bl_wrapper .information>p.text{
    color: black;
  }
  .bl_wrapper .information>p.title:before{
    background-color: black;
  }
  .bl_calcProcess_list_formula{
    display: flex !important;
  }
}
</style>
