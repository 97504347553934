<template>
    <div class="radio_wrapper">
        <label 
            :class="{checked:(value === checkedValue), disabled: disabled}"
            class="radio_label"
        >
            <input 
                type="radio" 
                :value="value"
                :checked="value === checkedValue"
                :disabled="disabled"
                @change="$emit('change', $event.target.value)"
            />
            <!--  ボタンに表示される文字 -->
            <div>
                <slot></slot>
            </div>
            <!--  ボタンに表示される追加の文字（太字） -->
            <slot name="sub"></slot>
            <span v-if="unit == 'm2'">
                m<sup>2</sup>
            </span>
            <span v-else-if="unit == 'W/m2K'">
                <span>W/m<sup>2</sup>K</span>
            </span>
            <span v-else-if="unit">
                <span>{{ unit }}</span>
            </span>
            <span v-if="disabled">（選択不可）</span>
        </label>
        <!-- labelに入れたくない要素を表示（ヘルプボタン・リンクボタンなど） -->
         <slot name="appendix"></slot>
    </div>
</template>

<script>
/**
 * 共通ラジオボタン
 * 
 * [プロパティ]
 * value ... ラジオボタンのvalue
 * checkedValue ... ラジオボタンの中でチェックされている値
 * unit ... 単位
 * disabled ... 選択可否
 * 
 * [イベント処理]
 * 選択時 ... checkedValueがv-modelの値で更新される
 * 
 * [利用例]
 * <app-radio
 *  v-model="region"
 *  value="A"
 * >
 *  1地域
 *  <span slot="sub">Unit</span>
 * </app-radio>
 * 
 * v-modelの値と、valueの値が同じ時にcheckedされます。
 * 呼び出し側のdataにモデル名：初期値のvalueを設定して下さい。
 */
export default {
    name:"AppRadio",
    model: {
        prop: 'checkedValue',
        event: 'change'
    },
    props: {
        /**
         * ラジオボタンのvalue
         */
        value: {
            type: String,
            required: true
        },
        /**
         * 選択済みの値：親コンポーネントでは指定せず、v-modelをもとに生成する
         */
        checkedValue: {
            type: String,
            required: true
        },
        /**
         * 単位
         */
        unit:{
            type: String
        },
        /**
         * 選択の可否：使用不可ならfalse
         */
        disabled:{
            type: Boolean
        },
    }
}
</script>

<style scoped>
.radio_wrapper{
    margin-bottom: 6px;
}
input[type="radio"]{
    display: none;
}
label{
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    margin: 2px 20px 0 18px;
    padding: 0;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    position: relative;
}
_:-ms-lang(x)::-ms-backdrop, label {
    display: inline-flex;
    line-height: 14px;
}
label:after{
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 4px;
    border-radius: 4px;
    background-color: white;
    border: 1px dotted #707070;
    left: -18px;
    top: 0;
    position: absolute;
    z-index: 10;
}

_:-ms-lang(x)::-ms-backdrop, label:after{/* IE11 */
    top: -3px
}
label.checked{
    font-weight: bold;
}
label.checked:before{
    content: "●";
    display: inline-block;
    height: 14px;
    color: #41B8D4;
    top: 1px;
    left: -17px;
    position: absolute;
    z-index: 200;
}
_:-ms-lang(x)::-ms-backdrop, label.checked:before {/* IE11 */
    top: -2px;
    padding-top: 2px;
}
label small{
    display: inline-block;
}
.bold{
    font-weight: bold;
}
.shosai_only{
    display: inline-block;
    padding: 2px 0;
    color:#41B8D4;
    font-size: 12px;
    font-weight: bolder;
}

/* disabledのとき */
label.disabled{
    color: #777;
}
label.disabled:hover{
    cursor: default;
}
label.disabled:after{
    color: #777;
    background-color: #f3f3f3;
    border: 1px solid #aaa;
}
.app_box.disabled label{
    color: #777;
}
.app_box.disabled label:after{
    color: #777;
    background-color: #f3f3f3;
    border: 1px solid #ccc;
}

/* リンクボタンのレイアウト調整 */
.radio_wrapper >>> .link_button{
    margin-top: 0;
    margin-left: 16px;
}
</style>