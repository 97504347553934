<template>
<div v-if="errorData.length != 0">
    <ul class="error_list">
        <li
            v-for="(item,index) in errorData"
            :key="index"
        >
            {{item}}
        </li>
    </ul>
</div>
</template>

<script>
/**
 * エラーリストの表示部分
 * [算出プロパティ]
 * errorData ... エラー文の配列
 * 
 * [利用例]
 * <app-error-list/>
 */
import { mapGetters } from 'vuex'

export default {
    name:"AppErrorList",
    computed: {
        /**
         * Array errorData エラー文の配列
         */
        ...mapGetters('error', {
            errorData:'getError',
        })
    },
}
</script>

<style scoped>
ul.error_list{
    display: block;
    width: 70%;
    max-width: 900px;
    margin: 55px auto 20px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bolder;
    line-height: 1.1em;
    color: rgb(58, 58, 58);
    background-color: rgb(255, 255, 225);
    border-top: 1px solid rgb(255, 159, 176);
    border-left: 1px solid rgb(255, 159, 176);
    border-bottom: 2px solid rgb(255, 159, 176);
    border-right: 2px solid rgb(255, 159, 176);
    position:relative;
}
.error_list li{
    list-style-type: none;

}
</style>