import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import calc from '@/store/modules/calc.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    calc,
  },
  plugins: [createPersistedState({storage: window.sessionStorage})],
  strict: process.env.NODE_ENV !== 'production'
})
