<template>
<div class="section_wrap">
    <section class="info_section">
        <slot></slot>
    </section>
</div>
</template>

<script>
/**
 * 見出しなしセクション
 * 
 * [利用例]
 * <info-section id="envelope">
 *     (ここに中身が入ります)
 * </info-section>
 */
export default {
    name:"InfoSection",
}
</script>

<style scoped>
.section_wrap{
    display: block;
}
.section_wrap:first-child{
    margin-top: 55px;
}
section.info_section {
    width: 90%;
    min-width: 900px;
    margin: 0 auto 30px;
}

/* レスポンシブ（タブレット縦よりウインドウ幅が狭いもの） */
@media screen and (max-width: 1000px) {
    section.info_section {
        width: calc(100% - 16px);
        min-width: 318px;
        margin: 2px 8px;
    }
}
</style>
