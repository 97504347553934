import Vue from 'vue'

/**
 * 計算用の値・結果管理モジュール
 * @module store/modules/calc
 */

const state = {
    cBal:null,
    cLeak:null,
    etaT:65,
    showEtaT:65,
    rNsa:0.9,
    shape:'1',
    vRtdSa:null,
    vRtdRa:null,
    vDSa:null,
    vDRa:null,
    etaTD:null,
    etaD:null,
    rDashVntD:null,
    rVntD:null,
    nD:null,
    vRtdMin:null,
    vDMin:null,
    nRtd:null,
    eta:null,
    rDashVntRtd:null,
    rVntRtd:null,
    diversionPartWidth:null,
    diversionPartLength:null,
    diversionPartAngle:null,
    error: true
}

const getters = {
    calcData(state){
        return state
    },
}

const actions = {
    // stateの更新を呼び出す
    update({commit}, values) {
        commit('update', values)
    },
    calc({state, commit}){
        const values = {}

        const alphaRad = parseFloat(state.diversionPartAngle) * (Math.PI / 180.0)
        values.rVntRtd = calcRVntRtd(state.vRtdRa, state.vRtdSa)
        values.rDashVntRtd = calcRDashVntRtd(state.shape, values.rVntRtd)
        values.showEtaT = calcEtaT(state.etaT)
        values.eta = calcEta(state.vRtdRa, state.vRtdSa, values.showEtaT, values.rDashVntRtd)
        values.nRtd = calcNRtd(state.shape, values.eta, values.rDashVntRtd, state.diversionPartWidth, state.diversionPartLength, alphaRad)
        values.vDMin = calcVDMin(state.vDSa, state.vDRa)
        values.vRtdMin = calcVRtdMin(state.vRtdSa, state.vRtdRa)
        values.nD = calcND(values.nRtd, values.vRtdMin, values.vDMin)
        values.rVntD = calcRVntD(state.vDSa, state.vDRa)
        values.rDashVntD = calcRDashVntD(state.shape, values.rVntD)
        values.etaD = calcEtaD(state.shape, values.nD, values.rDashVntD, state.diversionPartWidth, state.diversionPartLength, alphaRad)
        values.etaTD = calcEtaTD(state.vDSa, state.vDRa, values.etaD, values.rDashVntD)
        const hasError = checkError(values.showEtaT, state.vDSa, state.vDRa, state.vRtdSa, state.vRtdRa, state.shape, state.diversionPartWidth, state.diversionPartLength, state.diversionPartAngle)
        values.error = hasError
        values.cBal = calcCBal(hasError, values.etaTD, values.showEtaT)
        values.cLeak = calcCLeak(hasError, state.vDSa, state.vDRa)
        
        commit('update', values)
    },
}

const mutations = {
    // stateの更新
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

function calcNRtd(shape, eta, rValue, diversionPartWidth, diversionPartLength, alphaRad) {
    const width = parseFloat(diversionPartWidth)
    const length = parseFloat(diversionPartLength)

    const maxI = Math.pow(10.0, 4)
    let nOne = Math.pow(10.0, -8)
    let nTwo = Math.pow(10.0, 8)
    const tol = Math.pow(10.0, -8)
    let nValue
    let i = 0

    while (nTwo - nOne > tol) {
        i = i + 1
        nValue = (nOne + nTwo) / 2
        const left = fValue(shape, eta, nOne, rValue, width, length, alphaRad)
        const right = fValue(shape, eta, nValue, rValue, width, length, alphaRad)
        if (left * right < 0) {
            nTwo = nValue
        } else {
            nOne = nValue
        }
        if (i == maxI) break
    }

    nValue = (nOne + nTwo) / 2

    return nValue
}

function fValue(shape, eta, nValue, rValue, diversionPartWidth, diversionPartLength, alphaRad) {
    if (shape == '1') return eta - etaCrossflow(nValue, rValue)
    else if (shape == '2') return eta - etaComplex(nValue, rValue, diversionPartWidth, diversionPartLength, alphaRad)
    else console.log('Invalid Shape')
}

function etaCrossflow(nValue, rValue) {
    if (rValue == 0) rValue = Math.pow(10, -8)

    const eta = 1 - Math.exp((Math.exp(-1 * Math.pow(nValue, 0.78) * rValue) - 1) / (Math.pow(nValue, -0.22) * rValue))

    return eta
}

function etaComplex(nValue, rValue, diversionPartWidth, diversionPartLength, alpha) {
    if (rValue == 1) rValue = 1 - Math.pow(10, -8)

    const zeta = diversionPartWidth / diversionPartLength * Math.sin(alpha) * Math.cos(alpha)
    const xi = 0.0457143 * Math.pow(nValue, 2) + 0.0691429 * nValue + 0.9954286
    const nvalue = (1 + zeta / xi) * nValue
    const a = -1 * (1 - rValue)
    const eta = (1 - Math.exp(a * nvalue)) / (1 - rValue * Math.exp(a * nvalue))

    return eta
}

function calcRVntRtd(vRtdRa, vRtdSa) {
    const vRtdRaValue = parseFloat(vRtdRa)
    const vRtdSaValue = parseFloat(vRtdSa)
    if (vRtdRaValue > vRtdSaValue) {
        return vRtdSaValue / vRtdRaValue
    } else {
        return vRtdRaValue / vRtdSaValue
    }
}

function calcRDashVntRtd(shape, rVntRtd) {
    if (shape == '2' && rVntRtd == 1) {
        return 1 - Math.pow(10, -8)
    } else {
        return rVntRtd
    }
}

function calcEtaT(etaT) {
    const etaTInput = parseFloat(etaT) / 100.0
    return Math.min(0.95, etaTInput)
}

function calcEta(vRtdRa, vRtdSa, etaT, rDashVntRtd) {
    const vRtdRaValue = parseFloat(vRtdRa)
    const vRtdSaValue = parseFloat(vRtdSa)
    if (vRtdRaValue > vRtdSaValue) {
        return etaT
    } else {
        return etaT / rDashVntRtd
    }
}

function calcVDMin(vDSa, vDRa) {
    const vDSaValue = parseFloat(vDSa)
    const vDRaValue = parseFloat(vDRa)
    return Math.min(vDSaValue, vDRaValue)
}

function calcVRtdMin(vRtdSa, vRtdRa) {
    const vRtdSaValue = parseFloat(vRtdSa)
    const vRtdRaValue = parseFloat(vRtdRa)
    return Math.min(vRtdSaValue, vRtdRaValue)
}

function calcND(nRtd, vRtdMin, vDMin) {
    return nRtd * vRtdMin / vDMin
}

function calcRVntD (vDSa, vDRa) {
    var vDSaValue = parseFloat(vDSa)
    var vDRaValue = parseFloat(vDRa)
    if (vDRaValue > vDSaValue) {
        return vDSaValue / vDRaValue
    } else {
        return vDRaValue / vDSaValue
    }
}

function calcRDashVntD(shape, rVntD) {
    if (shape == "2" && rVntD == 1) {
        return 1 - Math.pow(10, -8)
    } else {
        return rVntD
    }
}

function calcEtaD(shape, nD, rDashVntD, diversionPartWidth, diversionPartLength, alphaRad) {
    let raw
    if (shape == 1) {
        raw = 1 - Math.exp(
                    (Math.exp(-1 * Math.pow(nD, 0.78) * rDashVntD) - 1)
                                / (Math.pow(nD, -0.22) * rDashVntD))
    } else {
        const width = parseFloat(diversionPartWidth)
        const length = parseFloat(diversionPartLength)
        raw = (1 - Math.exp(
                    -1
                    * (1 - rDashVntD)
                    * (1 + ((width / length) * Math.sin(alphaRad) * Math.cos(alphaRad)
                            / (0.0457143 * Math.pow(nD, 2) + 0.0691429 * nD + 0.9954286)))
                    * nD))
                / (1 - rDashVntD * Math.exp(
                    -1
                    * (1 - rDashVntD)
                    * (1 + ((width / length) * Math.sin(alphaRad) * Math.cos(alphaRad))
                            / (0.0457143 * Math.pow(nD, 2) + 0.0691429 * nD + 0.9954286))
                    * nD))
    }

    //誤差修正
    return Math.ceil(raw * 100000000) / 100000000
}

function calcEtaTD(vDSa, vDRa, etaD, rDashVntD) {
    const vDSaValue = parseFloat(vDSa)
    const vDRaValue = parseFloat(vDRa)
    if (vDRaValue > vDSaValue) {
        return etaD
    }
    else {
        return etaD * rDashVntD
    }
}

function checkError(etaT, vDSa, vDRa, vRtdSa, vRtdRa, shape, diversionPartWidth, diversionPartLength, diversionPartAngle) {
    const vDSaValue = parseFloat(vDSa)
    const vDRaValue = parseFloat(vDRa)
    const vRtdSaValue = parseFloat(vRtdSa)
    const vRtdRaValue = parseFloat(vRtdRa)

    if (isNaN(vRtdSaValue)) return true
    if (isNaN(vRtdRaValue)) return true
    if (isNaN(vDSaValue)) return true
    if (isNaN(vDRaValue)) return true
    if (etaT < 0.4) return true
    if (vRtdSaValue < vRtdRaValue * 0.5) return true
    if (vRtdSaValue > vRtdRaValue * 2.0) return true

    if (shape == "2") {
        const width = parseFloat(diversionPartWidth)
        const length = parseFloat(diversionPartLength)
        const angle = parseFloat(diversionPartAngle)

        if (isNaN(width) || isNaN(length) || isNaN(angle)) return true
        if (width < 0.01 || 2.00 < width) return true
        if (length < 0.01 || 3.00 < length) return true
        if (angle < 15 || 60 < angle) return true
    }

    return false
}

function calcCBal(hasError, etaTD, etaT) {
    if (hasError == true) return ""
    const cBal = etaTD / etaT
    return Math.floor(cBal * 100) / 100
}

function calcCLeak(hasError, vDSa, vDRa) {
    if (hasError == true) return ""
    const vDSaValue = parseFloat(vDSa)
    const vDRaValue = parseFloat(vDRa)
    if (vDRaValue > vDSaValue) {
        const Cleak = vDSaValue / vDRaValue
        return Math.floor(Cleak * 100) / 100
    } else if (vDRaValue <= vDSaValue) {
        return 1
    } else {
        return ""
    }
}